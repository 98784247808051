import * as dayjs from 'dayjs'
import { Notification } from 'element-ui'
import * as codeGeneretor from 'voucher-code-generator'
let rowspanArray
function computeSpanRow (data, option) {
  rowspanArray = []
  const tempRow = []
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < option.length; j++) {
      const index = option[j].index
      const field = option[j].field

      if (i === 0) {
        tempRow[index] = 0
        rowspanArray[index] = []
        rowspanArray[index].push(1)
      } else {
        if (data[i][field] === data[i - 1][field]) {
          rowspanArray[index][tempRow[index]] += 1
          rowspanArray[index].push(0)
        } else {
          rowspanArray[index].push(1)
          tempRow[index] = i
        }
      }
    }
  }
}
function is (option, index) {
  for (let i = 0; i < option.length; i++) {
    if (option[i].index === index) {
      return true
    }
  }
  return false
}
export default {
  spanRow ({ row, column, rowIndex, columnIndex }, data, option) {
    if (rowIndex === 0 && columnIndex === 0) {
      computeSpanRow(data, option)
    }
    if (is(option, columnIndex)) {
      const rowspan = rowspanArray[columnIndex][rowIndex]
      const colspan = rowspan > 0 ? 1 : 0
      return {
        rowspan: rowspan,
        colspan: colspan
      }
    }
    return {
      rowspan: 1,
      colspan: 1
    }
  },
  formatDate (date, format = 'YYYY-MM-DD HH:mm:ss') {
    if (date) {
      return dayjs(date).format(format)
    } else {
      return '-'
    }
  },
  generateDiscountCode () {
    return codeGeneretor.generate({
      prefix: 'DA-',
      postfix: '-2021',
      length: 5,
      count: 1
    })[0].toUpperCase()
  },
  generateCurrency (num) {
    if (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce(function (acc, num, i, orig) {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + ' ₮'
    }
  },
  isArray (value) {
    return Object.prototype.toString.call(value) === '[object Array]'
  },
  flattenObjectArrayByPropName
}
export function flattenObjectArrayByPropName (treeData = [], prop = 'children', parent, addParentProp = false) {
  return treeData.reduce((flattenArray, current) => {
    addParentProp && Object.assign(current, { parent })
    switch (Object.prototype.toString.call(current)) {
      case '[object Array]': flattenArray = [...flattenArray, ...flattenObjectArrayByPropName(current, prop, parent, addParentProp)]; break
      case '[object Object]':
        flattenArray = [...flattenArray, current]
        // eslint-disable-next-line no-prototype-builtins
        if (current.hasOwnProperty(prop) && Object.prototype.toString.call(current[prop]) === '[object Array]') {
          const parent = { ...current }
          delete parent[prop]
          flattenArray = [...flattenArray, ...flattenObjectArrayByPropName(current[prop], prop, parent, addParentProp)]
        }
        break
      default: flattenArray = [...flattenArray, current]; break
    }
    return flattenArray
  }, [])
}
export function arrayIncludes (baseArray, searchArray) {
  let isFound = false
  searchArray.forEach(element => {
    if (baseArray.includes(element)) {
      isFound = true
    }
  })
  return isFound
}
export function formatPrice (number) {
  const val = (number / 1).toFixed(0).replace('.', '')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export function showNotif (type, title, msg) {
  if (type !== 'error') {
    Notification({
      title: title,
      message: msg,
      type: type
    })
  } else {
    Notification.error({
      title: title,
      message: msg
    })
  }
}
